/**
 * This is a minimal example of sigma. You can use it as a base to write new
 * examples, or reproducible test cases for new issues, for instance.
 */

import Graph from "graphology";
import MultiDirectedGraph from "graphology";
import Sigma from "sigma";
import { circular } from "graphology-layout";
import { PlainObject } from "sigma/types";
import { animateNodes } from "sigma/utils/animate";
import dataFromJson from "./data.json";
import FA2Layout from "graphology-layout-forceatlas2/worker";
import forceAtlas2 from "graphology-layout-forceatlas2";

import { Coordinates, EdgeDisplayData, NodeDisplayData } from "sigma/types";

// Initialize the graph object with data
const graph = new MultiDirectedGraph({
  multi: true,
});
graph.import(dataFromJson);

// Retrieve some useful DOM elements:
const container = document.getElementById("sigma-container") as HTMLElement;
const searchInput = document.getElementById("search-input") as HTMLInputElement;
const searchSuggestions = document.getElementById("suggestions") as HTMLDataListElement;

const FA2Button = document.getElementById("forceatlas2") as HTMLElement;
const FA2StopLabel = document.getElementById("forceatlas2-stop-label") as HTMLElement;
const FA2StartLabel = document.getElementById("forceatlas2-start-label") as HTMLElement;

const randomButton = document.getElementById("random") as HTMLElement;
const resetGraphButton = document.getElementById("resetgraph") as HTMLElement;

const circularButton = document.getElementById("circular") as HTMLElement;

/** FA2 LAYOUT **/
/* This example shows how to use the force atlas 2 layout in a web worker */

// Graphology provides a easy to use implementation of Force Atlas 2 in a web worker
const sensibleSettings = forceAtlas2.inferSettings(graph);
const fa2Layout = new FA2Layout(graph, {
  settings: sensibleSettings,
});

// A button to trigger the layout start/stop actions

// A variable is used to toggle state between start and stop
let cancelCurrentAnimation: (() => void) | null = null;

// correlate start/stop actions with state management
function stopFA2() {
  fa2Layout.stop();
  FA2StartLabel.style.display = "flex";
  FA2StopLabel.style.display = "none";
}
function startFA2() {
  if (cancelCurrentAnimation) cancelCurrentAnimation();
  fa2Layout.start();
  FA2StartLabel.style.display = "none";
  FA2StopLabel.style.display = "flex";
}

// the main toggle function
function toggleFA2Layout() {
  if (fa2Layout.isRunning()) {
    stopFA2();
  } else {
    startFA2();
  }
}
// bind method to the forceatlas2 button
FA2Button.addEventListener("click", toggleFA2Layout);

/** RANDOM LAYOUT **/
/* Layout can be handled manually by setting nodes x and y attributes */
/* This random layout has been coded to show how to manipulate positions directly in the graph instance */
/* Alternatively a random layout algo exists in graphology: https://github.com/graphology/graphology-layout#random  */
function randomLayout() {
  // stop fa2 if running
  if (fa2Layout.isRunning()) stopFA2();
  if (cancelCurrentAnimation) cancelCurrentAnimation();

  // to keep positions scale uniform between layouts, we first calculate positions extents
  const xExtents = { min: 0, max: 0 };
  const yExtents = { min: 0, max: 0 };
  graph.forEachNode((node, attributes) => {
    xExtents.min = Math.min(attributes.x, xExtents.min);
    xExtents.max = Math.max(attributes.x, xExtents.max);
    yExtents.min = Math.min(attributes.y, yExtents.min);
    yExtents.max = Math.max(attributes.y, yExtents.max);
  });
  const randomPositions: PlainObject<PlainObject<number>> = {};
  graph.forEachNode((node) => {
    // create random positions respecting position extents
    randomPositions[node] = {
      x: Math.random() * (xExtents.max - xExtents.min),
      y: Math.random() * (yExtents.max - yExtents.min),
    };
  });
  // use sigma animation to update new positions
  cancelCurrentAnimation = animateNodes(graph, randomPositions, { duration: 2000 });
}

// bind method to the random button
randomButton.addEventListener("click", randomLayout);

/** CIRCULAR LAYOUT **/
/* This example shows how to use an existing deterministic graphology layout */
function circularLayout() {
  // stop fa2 if running
  if (fa2Layout.isRunning()) stopFA2();
  if (cancelCurrentAnimation) cancelCurrentAnimation();

  //since we want to use animations we need to process positions before applying them through animateNodes
  const circularPositions = circular(graph, { scale: 100 });
  //In other context, it's possible to apply the position directly we : circular.assign(graph, {scale:100})
  cancelCurrentAnimation = animateNodes(graph, circularPositions, { duration: 2000, easing: "linear" });
}

// bind method to the random button
circularButton.addEventListener("click", circularLayout);

/** instantiate sigma into the container **/
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const renderer = new Sigma(graph, container);



//
// Drag'n'drop feature
// ~~~~~~~~~~~~~~~~~~~
//

// State for drag'n'drop
let draggedNode: string | null = null;
let isDragging = false;

// On mouse down on a node
//  - we enable the drag mode
//  - save in the dragged node in the state
//  - highlight the node
//  - disable the camera so its state is not updated
renderer.on("downNode", (e) => {
  isDragging = true;
  draggedNode = e.node;
  graph.setNodeAttribute(draggedNode, "highlighted", true);
});

// On mouse move, if the drag mode is enabled, we change the position of the draggedNode
renderer.getMouseCaptor().on("mousemovebody", (e) => {
  if (!isDragging || !draggedNode) return;

  // Get new position of node
  const pos = renderer.viewportToGraph(e);

  graph.setNodeAttribute(draggedNode, "x", pos.x);
  graph.setNodeAttribute(draggedNode, "y", pos.y);

  // Prevent sigma to move camera:
  e.preventSigmaDefault();
  e.original.preventDefault();
  e.original.stopPropagation();
});

// On mouse up, we reset the autoscale and the dragging mode
renderer.getMouseCaptor().on("mouseup", () => {
  if (draggedNode) {
    graph.removeNodeAttribute(draggedNode, "highlighted");
  }
  isDragging = false;
  draggedNode = null;
});

// Disable the autoscale at the first down interaction
renderer.getMouseCaptor().on("mousedown", () => {
  if (!renderer.getCustomBBox()) renderer.setCustomBBox(renderer.getBBox());
});


// import ForceSupervisor from "graphology-layout-force/worker";

// const layout = new ForceSupervisor(graph, { isNodeFixed: (_, attr) => attr.highlighted });
// layout.start();


startFA2();
setTimeout(() => {
  //layout.stop();
  stopFA2()
},
  3000);

import saveAsPNG from "./saveAsPNG";

// Bind save button:
const saveBtn = document.getElementById("save-as-png") as HTMLButtonElement;
saveBtn.addEventListener("click", () => {
  const layers = ["edges", "nodes", "edgeLabels", "labels"].filter(
    (id) => !!(document.getElementById(`layer-${id}`) as HTMLInputElement).checked,
  );

  saveAsPNG(renderer, layers);
});


// Search

// Type and declare internal state:
interface State {
  hoveredNode?: string;
  searchQuery: string;

  // State derived from query:
  selectedNode?: string;
  selectedNodes?: Set<string>;
  suggestions?: Set<string>;

  // State derived from hovered node:
  hoveredNeighbors?: Set<string>;
}
const state: State = { searchQuery: "" };

// Feed the datalist autocomplete values:
searchSuggestions.innerHTML = graph
  .nodes()
  .map((node) => `<option value="${graph.getNodeAttribute(node, "label")}"></option>`)
  .join("\n");

// Actions:
function setSearchQuery(query: string) {
  state.searchQuery = query;

  if (searchInput.value !== query) searchInput.value = query;

  if (query) {
    const lcQuery = query.toLowerCase()//.split(" ", 3);
    const suggestions = graph
      .nodes()
      .map((n) => ({ id: n, label: graph.getNodeAttribute(n, "label") as string }))
      .filter(({ label }) => label?.toLowerCase().includes(lcQuery));

    // X If we have a single perfect match, them we remove the suggestions, and
    // we consider the user has selected a node through the datalist
    // autocomplete:
    if (suggestions.length === 1 && suggestions[0].label === query) {
      state.selectedNode = suggestions[0].id;
      state.suggestions = new Set(suggestions.map(({ id }) => id));

      // Move the camera to center it on the selected node:
      const nodePosition = renderer.getNodeDisplayData(state.selectedNode) as Coordinates;
      renderer.getCamera().animate(nodePosition, {
        duration: 500,
      });
    }
    // Else, we display the suggestions list:
    else {
      state.selectedNode = undefined;
      state.suggestions = new Set(suggestions.map(({ id }) => id));
    }
  }
  // If the query is empty, then we reset the selectedNode / suggestions state:
  else {
    state.selectedNode = undefined;
    state.suggestions = undefined;
  }

  // Refresh rendering:
  renderer.refresh();
}
function setHoveredNode(node?: string) {
  if (node) {
    state.hoveredNode = node;
    state.hoveredNeighbors = new Set(graph.neighbors(node));
  } else {
    state.hoveredNode = undefined;
    state.hoveredNeighbors = undefined;
    state.selectedNodes.delete(node);
    if(state.selectedNodes.size == 0)
    state.selectedNodes = undefined;
  }

  // Refresh rendering:
  renderer.refresh();
}

function setClickedNode(node?: string) {
  if (node) {
    if(state.hoveredNode === node){
      console.log('delete node')
      state.hoveredNode = undefined
    } else {
      state.hoveredNode = node;
    }

    // All clicked nodes
    if (state.selectedNodes == undefined)
      state.selectedNodes = new Set<string>()

    if (state.selectedNodes.has(node))
      state.selectedNodes.delete(node);
    else
      state.selectedNodes.add(node);



    const clickedHighlightedNodes = new Set<string>()

    state.selectedNodes.forEach((node) => {
      graph.neighbors(node).forEach((node) => {
        clickedHighlightedNodes.add(node)
      });
    });

    state.hoveredNeighbors = clickedHighlightedNodes;


    // Just where is nothing return everything as what it was:
    if(state.selectedNodes.size == 0)
      setHoveredNode(undefined);
  }
  // Refresh rendering:
  renderer.refresh();
}

resetGraphButton.addEventListener("click", () => {
  setHoveredNode(undefined);
  state.selectedNodes = undefined;
  // Refresh rendering:
  renderer.refresh();
});


// Bind search input interactions:
searchInput.addEventListener("input", () => {
  setSearchQuery(searchInput.value || "");
});
// Comment these to let the suggestion stay when click out the search input
// searchInput.addEventListener("blur", () => {
//   setSearchQuery("");
// });


// Commented To make all nodes clickable

// Bind graph interactions:
// renderer.on("enterNode", ({ node }) => {
//   setHoveredNode(node);
// });
// renderer.on("leaveNode", () => {
//   setHoveredNode(undefined);
// });

renderer.on("clickNode", ({ node }) => {
  setClickedNode(node);
});

// Render nodes accordingly to the internal state:
// 1. If a node is selected, it is highlighted
// 2. If there is query, all non-matching nodes are greyed
// 3. If there is a hovered node, all non-neighbor nodes are greyed
renderer.setSetting("nodeReducer", (node, data) => {
  const res: Partial<NodeDisplayData> = { ...data };

  if (state.hoveredNeighbors && !state.hoveredNeighbors.has(node) && !state.selectedNodes.has(node)) {
    res.label = "";
    res.color = "#f6f6f6";
  }

  if (state.selectedNode === node || (state.selectedNodes && state.selectedNodes.has(node))) {
    res.highlighted = true;
  } else if (state.suggestions && !state.suggestions.has(node)) {
    res.label = "";
    res.color = "#f6f6f6";
  }

  return res;
});

// Render edges accordingly to the internal state:
// 1. If a node is hovered, the edge is hidden if it is not connected to the
//    node
// 2. If there is a query, the edge is only visible if it connects two
//    suggestions
renderer.setSetting("edgeReducer", (edge, data) => {
  const res: Partial<EdgeDisplayData> = { ...data };

  // if (state.hoveredNode && !graph.hasExtremity(edge, state.hoveredNode)) {
  //   res.hidden = true;
  // }


  if (state.selectedNodes){
    res.hidden = true;
    state.selectedNodes.forEach(node => {
      if (graph.hasExtremity(edge, node)) {
        res.hidden = false;
      }
    });
  }
  (state.selectedNodes && (!state.selectedNodes.has(graph.source(edge)) || !state.selectedNodes.has(graph.target(edge)))) 
  // if (state.selectedNodes && (!state.selectedNodes.has(graph.source(edge)) || state.selectedNodes.has(graph.target(edge)))) {
  //   res.hidden = true;
  // }

  if (state.suggestions && (!state.suggestions.has(graph.source(edge)) || !state.suggestions.has(graph.target(edge)))) {
    res.hidden = true;
  }

  return res;
});


import axios from 'axios';

async function getApiData() {
  try {
    // 👇️ const data: GetUsersResponse
    const { data, status } = await axios.get<string>(
      'http://psm-project.test/api/v1/network-data',
      {
        headers: {
          Accept: 'application/json',
        },
      },
    );
    // 👇️ "response status is: 200"
    console.log('response status is: ', status);
    const apiData = JSON.parse(JSON.stringify(data, null, 4));

    graph.clear();
    graph.import(apiData)
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error message: ', error.message);
      return error.message;
    } else {
      console.log('unexpected error: ', error);
      return 'An unexpected error occurred';
    }
  }
}


const apiData = getApiData()

